import { lazy } from "react";

import HomepageTemplate from "pages";

const ContactUsTemplate = lazy(() => import("pages/ContactUs"));
const SustainabilityCommitmentTemplate = lazy(
	() => import("pages/SustainabilityCommitment")
);
const OurValuesAndCultureTemplate = lazy(
	() => import("pages/OurValuesAndCulture")
);
const HearFromOurTeamTemplate = lazy(() => import("pages/HearFromOurTeam"));
const NotFoundTemplate = lazy(() => import("pages/NotFound"));
const RecognitionAndAwardsTemplate = lazy(
	() => import("pages/RecognitionAndAwards")
);
const TherapyAreasTemplate = lazy(() => import("pages/TherapyAreas"));
const MediaCentreTemplate = lazy(() => import("pages/MediaCentre"));
const OurCovid19VaccineTemplate = lazy(() => import("pages/OurCovid19Vaccine"));
const OurCovid19VaccineV2Template = lazy(
	() => import("pages/OurCovid19VaccineV2")
);
const EnvironmentalProtectionTemplate = lazy(
	() => import("pages/EnvironmentalProtection")
);
const LeadingInClinicalTrialsTemplate = lazy(
	() => import("pages/LeadingInClinicalTrials")
);
const AccessToHeathcareTemplate = lazy(
	() => import("pages/AccessToHealthcare")
);
const HealthSystemStrengtheningTemplate = lazy(
	() => import("pages/HealthSystemStrengthening")
);
const SearchTemplate = lazy(() => import("pages/Search"));
const OurCovid19ResponseTemplate = lazy(
	() => import("pages/OurCovid19Response")
);
const AstrazenecaInVietnamTemplate = lazy(
	() => import("pages/AstrazenecaInVietnam")
);
const OurCountryLeaderTeamTemplate = lazy(() => import("pages/OurLeaderTeam"));
const PressReleasesTemplate = lazy(() => import("pages/PressReleases"));
const LatestNewsTemplate = lazy(() => import("pages/LatestNews"));
const ArticleNewsTemplate = lazy(() => import("pages/ArticleNews"));

export default {
	HOME: HomepageTemplate,
	ABOUT_US_A4: ContactUsTemplate,
	SUBSTAINABILITY_COMMITMENT_C1: SustainabilityCommitmentTemplate,
	ABOUT_US_A2: OurValuesAndCultureTemplate,
	WHY_CHOOSE_ASTRAZENECA_D1: HearFromOurTeamTemplate,
	PAGE_404: NotFoundTemplate,
	ABOUT_US_A11: RecognitionAndAwardsTemplate,
	WHAT_SCIENCE_CAN_DO_B1: TherapyAreasTemplate,
	MEDIA_CENTRE_E1: MediaCentreTemplate,
	WHAT_SCIENCE_CAN_DO_B3: OurCovid19VaccineTemplate,
	WHAT_SCIENCE_CAN_DO_B3_V2: OurCovid19VaccineV2Template,
	SUSTAINABILITY_C4: EnvironmentalProtectionTemplate,
	WHAT_SCIENCE_CAN_DO_B2: LeadingInClinicalTrialsTemplate,
	ACCESS_TO_HEALTHCARE_C2: AccessToHeathcareTemplate,
	HEALTH_SYSTEM_STRENGTHENING_C3: HealthSystemStrengtheningTemplate,
	SEARCH: SearchTemplate,
	ABOUT_US_A3: OurCovid19ResponseTemplate,
	ASTRAZENECA_VN: AstrazenecaInVietnamTemplate,
	WORKPLACE_LEADERSHIP: OurCountryLeaderTeamTemplate,
	PRESS_RELEASES_E2: PressReleasesTemplate,
	LATEST_NEWS_E3: LatestNewsTemplate,
	ARTICLE_NEWS: ArticleNewsTemplate,
} as const;
