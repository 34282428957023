import React from "react";

import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { InformationCard } from "components/molecules/InformationCard";
import { Link } from "components/utils/Link";

SwiperCore.use([Pagination, Navigation]);

export interface Props {
	contents: Array<{
		id: number;
		imgSrc: string;
		imgAlt?: string;
		date: string;
		description: string;
		href: string;
		target?: string;
	}>;
}

export const LatestNewsSlider: React.FC<Props> = ({ contents }) => {
	return (
		<div className="m-latestnewsslider">
			<Swiper
				spaceBetween={30}
				slidesPerView={1}
				speed={700}
				navigation={{
					nextEl: ".m-latestnewsslider_next",
					prevEl: ".m-latestnewsslider_prev",
				}}
				pagination={{
					el: ".m-latestnewsslider_pagination",
					bulletClass: "m-latestnewsslider_bullet",
					bulletActiveClass: "m-latestnewsslider_bullet-active",
					modifierClass: "m-latestnewsslider_pagination-",
					clickable: true,
				}}
				breakpoints={{
					576: {
						slidesPerView: 2,
					},
					768: {
						slidesPerView: 3,
					},
				}}
				watchOverflow
			>
				{contents.map(
					({ id, imgSrc, imgAlt, date, description, href, target }) => (
						<SwiperSlide className="m-latestnewsslider_item" key={id}>
							{href ? (
								<Link to={href} target={target}>
									<InformationCard
										date={date}
										description={description}
										imgAlt={imgAlt}
										imgSrc={imgSrc}
										type="award"
									/>
								</Link>
							) : (
								<InformationCard
									date={date}
									description={description}
									imgAlt={imgAlt}
									imgSrc={imgSrc}
									type="award"
								/>
							)}
						</SwiperSlide>
					)
				)}
			</Swiper>
			<div className="m-latestnewsslider_direction">
				<div className="m-latestnewsslider_pagination" />
				<div className="m-latestnewsslider_navigation">
					<span className="m-latestnewsslider_prev" />
					<span className="m-latestnewsslider_next" />
				</div>
			</div>
		</div>
	);
};
